import { CustomVideo, FlexBox, theme, trackEvent } from '@rario/shared-components'
import { MaxWidthLayout } from 'components/Layout/Layout.styles'
import { AppHeroCardProps } from 'interfaces/cmsContentTypes'
import Link from 'next/link'

import { FirstFoldWrapper } from './HeroCard.styles'
import { useContext, useEffect } from 'react'
import { buyBackModalTypeProps, ModalContext } from 'components/ModalManager/ModalProvider'

const AppHeroCard: React.FunctionComponent<{
  card: AppHeroCardProps
  videoCallback: ({ thumbnail, video }: { thumbnail?: string; video?: string }) => void
}> = ({ card, videoCallback }) => {
  const { media } = card
  const mediaUrl = media?.backgroundImage?.file?.url || media.thumbnail?.file?.url
  const { setBuyBackModalType, setIsBuyBackModalOpen } = useContext(ModalContext)

  if (!mediaUrl) {
    return null
  }

  const commonEventData = {
    click_type: 'banner',
    click_action: 'homepage_first_fold_cards',
    category: 'homepage',
    banner_title: card?.title,
  }

  useEffect(() => {
    trackEvent('Section Viewed', 'track', {
      sub_category: 'homepage-special_app_hero_card',
      ...commonEventData,
      click_type: 'NULL',
      user_type: card?.userType?.[0] || null,
      type: 'carousel_cards',
    })
  }, [])

  const handleBannerClick = () => {
    setBuyBackModalType((card?.heading as buyBackModalTypeProps) || 'info')
    setIsBuyBackModalOpen(true)
  }

  return (
    <FlexBox
      onClick={handleBannerClick}
      backgroundImage={`url(https:${mediaUrl})`}
      backgroundSize={'cover'}
      backgroundRepeat="no-repeat"
      backgroundPosition={'50% 50%'}
      width="100%"
      minHeight={'215px'}
      position={'relative'}
      flexDirection={'column'}
      className="home-first-fold"
      mb={'15px'}
    >
      <FlexBox alignSelf="center">
        <FirstFoldWrapper maxHeight="225px" maxWidth={['414px', theme.maxDesktopLayoutWidth]}>
          <MaxWidthLayout>
            <FlexBox alignItems={'center'} flexDirection={'column'}>
              {media?.backgroundVideo && (
                <FlexBox
                  mb={'20px'}
                  justifyContent={'center'}
                  position={'absolute'}
                  width={'414px'}
                  height={'100%'}
                >
                  <CustomVideo
                    height={'100%'}
                    width={'100%'}
                    media={{
                      poster: media?.thumbnail?.file?.url,
                      src: media?.backgroundVideo?.file?.url || '',
                    }}
                    mediaType="video/mp4"
                    isMute
                  />
                </FlexBox>
              )}
              {media?.isClickableBanner && media?.bannerLinkUrl && (
                <FlexBox position={'absolute'} height={'75px'} width="55%" right={'0'} bottom={'0'}>
                  <Link href={media?.bannerLinkUrl}>
                    <FlexBox
                      height={'75px'}
                      width="100%"
                      cursor="pointer"
                      onClick={() => {
                        trackEvent('Section Interacted', 'click', {
                          sub_category: 'homepage-first_fold_secondary_cta',
                          link: media?.bannerLinkUrl,
                          ...commonEventData,
                        })
                      }}
                    />
                  </Link>
                </FlexBox>
              )}
              {media?.isVideo && !media?.backgroundVideo && (
                <FlexBox
                  height={'85px'}
                  width="45%"
                  position={'absolute'}
                  left={'0'}
                  top="88px"
                  cursor="pointer"
                  onClick={() => {
                    videoCallback({
                      thumbnail: media?.thumbnail?.file?.url,
                      video: media?.video?.file?.url,
                    })
                  }}
                />
              )}
            </FlexBox>
          </MaxWidthLayout>
        </FirstFoldWrapper>
      </FlexBox>
    </FlexBox>
  )
}
export default AppHeroCard
