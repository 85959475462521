import { Carousel, CustomVideo, FlexBox, Modal } from '@rario/shared-components'
import AppHeroCard from 'components/HeroCardCarousel/AppHerocard'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { AppHeroCardProps as AppHeroCardType } from 'interfaces/cmsContentTypes'
import { MobileHomePage } from 'interfaces/homePage'
import { PLATFORM_ORIGIN } from 'config'
import { useState } from 'react'
import { getVisibleHomePageBanners } from 'utils/cmsUtils'
import { getWelcomeState } from 'utils/utils'

const FirstFoldCarousel: React.FunctionComponent<{ data: MobileHomePage }> = ({ data }) => {
  const { user: userData } = useCustomUser()

  const welcomeState = getWelcomeState(userData)
  const firstFoldCarouselData = data?.pageContents.filter(
    (item) =>
      (item?.platform === PLATFORM_ORIGIN || !item?.platform) &&
      (item?.userType?.[0] === welcomeState || !item.userType?.[0])
  )

  const defaultBanner: AppHeroCardType = {
    media: {
      isVideo: false,
      isClickableBanner: true,
      backgroundImage: {
        title: 'default banner',
        description: '',
        file: {
          details: {
            size: 101000,
            image: {
              width: 750,
              height: 450,
            },
          },
          fileName: 'default-home-banner.webp',
          contentType: 'image/png',
          url: `//assets.rario.com/RARIO/App/images/home/default-home-banner-1.webp`,
        },
      },
      bannerLinkUrl: '/marketplace',
    },
    title: 'default banner',
  }
  const homePageBanners = getVisibleHomePageBanners(firstFoldCarouselData, userData?.phoneIsoCode)

  const [playVideo, setPlayVideo] = useState<{ thumbnail?: string; video?: string }>({})
  return (
    <>
      {homePageBanners?.length > 0 ? (
        homePageBanners?.length === 1 ? (
          <>
            <AppHeroCard
              card={homePageBanners?.[0]}
              videoCallback={({ thumbnail, video }: { thumbnail?: string; video?: string }) =>
                setPlayVideo({ thumbnail, video })
              }
            />
          </>
        ) : (
          <Carousel
            slidesToScroll={1}
            dragFree={false}
            reInitCarousel
            speed={6}
            autoPlay={Object.keys(playVideo).length === 0}
            arrows={false}
            loop={true}
            sidesFadeEffect={false}
            showCustomDots
          >
            {homePageBanners?.map((item, index) => (
              <AppHeroCard
                key={`hero-card-${index}`}
                card={item}
                videoCallback={({ thumbnail, video }: { thumbnail?: string; video?: string }) =>
                  setPlayVideo({ thumbnail, video })
                }
              />
            ))}
          </Carousel>
        )
      ) : (
        <AppHeroCard
          card={defaultBanner}
          videoCallback={({ thumbnail, video }: { thumbnail?: string; video?: string }) =>
            setPlayVideo({ thumbnail, video })
          }
        />
      )}
      {Object.keys(playVideo).length !== 0 && (
        <FlexBox>
          <Modal
            visible={true}
            showCloseIcon={true}
            background="#000"
            onClick={() => setPlayVideo({})}
            isOutsideClick={true}
            showTill={5000}
            variant="fullScreenVideo"
          >
            <FlexBox maxWidth={'50vh'} mx="auto">
              <CustomVideo
                height={'100%'}
                width={'100%'}
                media={{
                  poster: playVideo?.thumbnail,
                  src: playVideo?.video || '',
                }}
                loop={false}
                mediaType="video/mp4"
                showManualControls
                isMute={false}
                controlsPositionBottom={'20px'}
                controlsPositionLeft={'10px'}
                onEnd={() => setPlayVideo({})}
              />
            </FlexBox>
          </Modal>
        </FlexBox>
      )}
    </>
  )
}
export default FirstFoldCarousel
