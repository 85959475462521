import { TourPath } from 'interfaces/appTour'

export const isTourVisited = (pathname: TourPath): boolean => {
  const appTourData = JSON.parse(localStorage.getItem('appTourData') as string)
  const appTourDataMap = new Map<TourPath, boolean>(appTourData)
  if (appTourDataMap.has(pathname)) {
    return !!appTourDataMap.get(pathname)
  }
  return false
}

export const setTourVisited = (pathname: TourPath) => {
  const appTourData = JSON.parse(localStorage.getItem('appTourData') as string)
  const appTourDataMap = new Map<TourPath, boolean>(appTourData)
  appTourDataMap.set(pathname, true)
  localStorage.setItem('appTourData', JSON.stringify([...appTourDataMap]))
}
