import { TourPath } from 'interfaces/appTour'
import { useState, useEffect } from 'react'
import { isTourVisited } from 'utils/appTour'

export default function useAppTour(pathname: TourPath) {
  const [showAppTour, setShowAppTour] = useState<boolean>(false)

  useEffect(() => {
    const isVisited = isTourVisited(pathname)
    setTimeout(() => setShowAppTour(!isVisited), 100)
  }, [pathname])

  return showAppTour
}
