import { FlexBox, TextField, theme } from '@rario/shared-components'

const AppTourContent: React.FunctionComponent<{ heading: string; description: string }> = ({
  heading,
  description,
}) => {
  return (
    <FlexBox flexDirection={'column'}>
      <TextField
        fontSize={'24px'}
        lineHeight={'34px'}
        fontWeight={600}
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        p={0}
      >
        {heading}
      </TextField>

      <TextField
        fontSize={'14px'}
        lineHeight={'20px'}
        fontWeight={400}
        fontFamily={theme.fonts.secondary}
        color={theme.colors.whites[9]}
        p={0}
        mt={'12px'}
        content={description}
      />
    </FlexBox>
  )
}
export default AppTourContent
