import { IsoCodes } from 'interfaces'
import {
  AppHeroCardProps,
  EligibleCountryValues,
  Image,
  TabularContentFormat,
  Video,
} from 'interfaces/cmsContentTypes'

export const getCMSImageUrl = (url: string, image: Image): string => {
  if (url) return url.includes(':') ? url.split(':')[1] : url
  return image?.file?.url || ''
}

export const getCMSVideoUrl = (url: string, video: Video): string => {
  if (url) return url
  return video?.file?.url ? `https:${video.file.url}` : ''
}

const CMS_KEYS = {
  SYS: 'sys',
  METADATA: 'metadata',
  FIELDS: 'fields',
}

export const transformFields = (fields: any) => {
  let transformedFields = {}
  const keys = Object.keys(fields)

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    if (key === CMS_KEYS.SYS || key === CMS_KEYS.METADATA) {
      // Do Nothing
    } else if (key === CMS_KEYS.FIELDS) {
      transformedFields = transformFields(fields[key])
    } else if (
      typeof fields[key] === 'object' &&
      fields[key] !== null &&
      !Array.isArray(fields[key])
    ) {
      transformedFields = {
        ...transformedFields,
        [key]: transformFields(fields[key]),
      }
    } else if (Array.isArray(fields[key])) {
      transformedFields = {
        ...transformedFields,
        [key]: fields[key].map((x: any) => transformFields(x)),
      }
    } else {
      transformedFields = {
        ...transformedFields,
        [key]: fields[key],
      }
    }
  }
  return transformedFields
}

export const getTableData = (tableNode: TabularContentFormat) => {
  let tableData: string[][] = []
  let rowData: string[] = []

  tableNode?.content?.forEach((tableRow) => {
    tableRow?.content?.forEach((tableCell) =>
      rowData.push(
        tableCell?.content[0]?.content?.[0]?.nodeType === 'text'
          ? tableCell?.content[0]?.content?.[0].value
          : ''
      )
    )

    tableData = [...tableData, [...rowData]]
    rowData = []
  })

  return tableData
}

export const getAbbrevation = (str: string) => {
  const matches = str.match(/\b[a-zA-Z]/g)
  return (matches && matches.join('')) || ''
}

export function filterEmptyObjects<T>(sectionData: T[]): T[] {
  return sectionData?.filter((component) => !!Object.keys(component as Object).length)
}

// Add supported banner countries here
const ISOCodeCountryMap = new Map<IsoCodes, EligibleCountryValues>([
  [IsoCodes.INDIA, EligibleCountryValues.INDIA],
  [IsoCodes.AUSTRALIA, EligibleCountryValues.AUSTRALIA],
  [IsoCodes.UK, EligibleCountryValues.UK],
])

export const getVisibleHomePageBanners = (
  banners: AppHeroCardProps[],
  phoneIsoCode: string | undefined
) => {
  const visibleBanners = banners?.filter(
    ({ visibilityEndTime = Date.now(), visibilityStartTime = Date.now(), eligibleCountries }) => {
      const isVisible =
        new Date(visibilityStartTime) <= new Date() && new Date(visibilityEndTime) >= new Date()

      if (isVisible) {
        if (eligibleCountries?.includes(EligibleCountryValues.ALL)) {
          return true
        } else {
          return eligibleCountries?.includes(
            ISOCodeCountryMap.get(phoneIsoCode as IsoCodes) || EligibleCountryValues.OTHERS
          )
        }
      } else {
        return false
      }
    }
  )

  return visibleBanners
}
