import AppTourContent from 'components/AppTour/AppTourContent'
import { ReactourStep } from 'reactour'
import { tabsToggle } from './buyPage'

export const HomeTourConfig: ReactourStep[] = [
  {
    selector: `.buy-navigation`,
    content: (
      <AppTourContent
        heading="Own limited-edition Digital Player Cards"
        description="You can buy packs of player cards to build your collection or single cards from the Fan Marketplace to fine-tune it."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'top',
  },
  {
    selector: '.d3-navigation',
    content: (
      <AppTourContent
        heading="Play the ultimate cricket strategy game"
        description="Use your Digital Player Cards to form 3-member squads and compete in free contests during live matches to win big rewards!"
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'top',
  },
  {
    selector: '.wallet-icon',
    content: (
      <AppTourContent
        heading="Wallet"
        description="Signup bonus added! Check your wallet balance and transaction history here."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'left',
  },
  {
    selector: '.profile-navigation',
    content: (
      <AppTourContent
        heading="Your profile"
        description="You can manage your collection of Player Cards, review your transactions or send out referrals to bring in new friends."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'left',
  },
  {
    selector: `.referral-navigation`,
    content: (
      <AppTourContent
        heading="REFER"
        description="Bring more friends, win more rewards and have more fun!"
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'top',
  },
  {
    selector: `.side-nav-icon`,
    content: (
      <AppTourContent
        heading="Explore the app"
        description="Check out which leagues you can play on D3, send referral links to friends, manage your profile and carry out transactions - all from this handy menu."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'bottom',
  },
]

export const ProfileTourConfig: ReactourStep[] = [
  {
    selector: `.cards-tab`,
    content: (
      <AppTourContent
        heading="Digital Player Cards"
        description="Check out all the Player Cards in your collection. These are the cards you can use to form your teams for D3. You can also list these cards for sale on the Marketplace to fetch a higher price."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'bottom',
  },
  {
    selector: '.packs-tab',
    content: (
      <AppTourContent
        heading="Packs"
        description="Here you can view the Packs of Player Cards that you have purchased but not yet opened. The cards will be accessible in your collection only after you do so."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'bottom',
  },
  {
    selector: '.share-profile',
    content: (
      <AppTourContent
        heading="Share Profile"
        description="Click here to share your profile with your friends and show them what you've been up to on Rario. This can be done via whatsapp, social media, email or even SMS."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'left',
  },
  {
    selector: `.edit-profile`,
    content: (
      <AppTourContent
        heading="Edit Profile"
        description="Click on this icon to make changes to your profile like your name, email or phone number."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'bottom',
  },
  {
    selector: `.filter-search`,
    content: (
      <AppTourContent
        heading="Filter your search"
        description="Organize the search results according to leagues, tiers, nationality, player type and more."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'bottom',
  },
]

export const PacksTourConfig: ReactourStep[] = [
  {
    selector: `.${tabsToggle[0].className}`,
    content: (
      <AppTourContent
        heading="Packs of Cards"
        description="Buy packs with multiple player cards from your favorite teams. Use the same cards for entering contests the whole season and beyond.
        <br/>
        <br/>
        You can use your Rario Bonus to buy packs at half price!"
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'bottom',
  },
  {
    selector: `.${tabsToggle[1].className}`,
    content: (
      <AppTourContent
        heading="Single Cards Sold by Rario"
        description="Own cherry-picked Player Cards that have high potential to perform. These are made available for short periods of time so stay tuned to when they're released."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'bottom',
  },
  {
    selector: `.${tabsToggle[2].className}`,
    content: (
      <AppTourContent
        heading="Marketplace"
        description="Buy/Sell individual Player Cards amongst other fans in the Rario ecosystem.
        <br/>
        <br/>
        This is how you can fine-tune your collection to create a perfect D3 squad as well as earn through your tradings."
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'left',
  },
  {
    selector: `.about-cards`,
    content: (
      <AppTourContent
        heading="About Player Cards"
        description="Click here to learn what Digital Player Cards are, ways to buy them and how to use them in order to win big rewards!"
      />
    ),
    style: {
      padding: 0,
      background: 'transparent',
    },
    position: 'bottom',
  },
]
