import { NFTTokenDoc } from 'interfaces'
import { Hit } from 'react-instantsearch-core'

export enum BuyPageTabsEnum {
  // eslint-disable-next-line no-unused-vars
  LATEST_PACKS = 'packs',
  // eslint-disable-next-line no-unused-vars
  LATEST_PICKS = 'picks',
  // eslint-disable-next-line no-unused-vars
  FAN_MARKETPLACE = 'marketplace',
}

export enum UserCohortType {
  // eslint-disable-next-line no-unused-vars
  COHORT4 = 'COHORT4',
}

export type ToggleType = {
  className: string
  label: string
  value: BuyPageTabsEnum
}

export const tabsToggle: ToggleType[] = [
  {
    className: 'pack_of_cards',
    label: 'Packs of Cards',
    value: BuyPageTabsEnum.LATEST_PACKS,
  },
  {
    className: 'single_cards',
    label: 'Single Cards',
    value: BuyPageTabsEnum.LATEST_PICKS,
  },
  {
    className: 'marketplace',
    label: 'Marketplace',
    value: BuyPageTabsEnum.FAN_MARKETPLACE,
  },
]

export interface MarketplaceListProps {
  hits: Hit<NFTTokenDoc>[]
  activeIndexTitle: string
}

export interface facetValuesProps {
  [key: string]: string
}
