/* eslint-disable no-unused-vars */
import { PacksTourConfig, HomeTourConfig, ProfileTourConfig } from 'constant/appTour'
import { ReactourStep } from 'reactour'

export type AppTourProp = {
  pathname: TourPath
  showPracticeBannerCallback?: (showBanner: boolean) => void
}

export enum TourPath {
  HOME = 'home',
  PACKS = 'packs',
  PROFILE = 'profile',
}

export const TourStepsMap = new Map<TourPath, ReactourStep[]>([
  [TourPath.HOME, HomeTourConfig],
  [TourPath.PACKS, PacksTourConfig],
  [TourPath.PROFILE, ProfileTourConfig],
])
